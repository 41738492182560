import { HAIRSP } from './consts/characters';
import { UnitType } from './consts/events';
import { OneHourInMillis, OneMinuteInMillis, OneSecondInMillis } from './consts/time';
import { isValidDate } from './functions/validation';
import { floorTo } from './roundTo';

export function leftpad2(num: number): string {
  return String(num).padStart(2, '0');
}

export function formatTimeDuration(time?: number | null): string {
  if (time == null || !Number.isFinite(time) || time < 0) {
    return '';
  }
  if (time === 0) {
    return '00:00:00';
  }
  const sec = Math.floor(time / 1000);
  const min = Math.floor(sec / 60);
  const hour = Math.floor(min / 60);

  return `${leftpad2(hour)}:${leftpad2(min % 60)}:${leftpad2(sec % 60)}`;
}

export function timeDurationToMilliseconds(time: string): number {
  const durationInMilliseconds =
    Number(time.split(':')[0]) * OneHourInMillis +
    Number(time.split(':')[1]) * OneMinuteInMillis +
    Number(time.split(':')[2]) * OneSecondInMillis;
  return durationInMilliseconds < 0 ? Number.NaN : durationInMilliseconds;
}

export function formatHumanTimeDuration(time: number): string {
  if (!Number.isFinite(time) || time < 0) {
    return '';
  }
  if (time === 0) {
    return '0s';
  }
  let sec = Math.floor(time / 1000);
  let min = Math.floor(sec / 60);
  let hour = Math.floor(min / 60);
  const day = Math.floor(hour / 24);

  sec = sec % 60;
  min = min % 60;
  hour = hour % 24;

  return [
    day > 0 ? `${day}d` : '',
    hour > 0 ? `${hour}h` : '',
    min > 0 ? `${min}m` : '',
    sec > 0 ? `${sec}s` : '',
  ].join(' ');
}

export function formatHumanTimeDurationShort(time: number): string {
  if (!Number.isFinite(time) || time < 0) {
    return '';
  }
  if (time === 0) {
    return '0s';
  }
  let sec = Math.floor(time / 1000);
  let min = Math.floor(sec / 60);
  let hour = Math.floor(min / 60);
  const day = Math.floor(hour / 24);

  sec = sec % 60;
  min = min % 60;
  hour = hour % 24;

  return [
    day > 0 ? `${day}d` : '',
    hour > 0 && time < 86400000 ? `${hour}h` : '',
    min > 0 && time < 7200000 ? `${min}m` : '',
    sec > 0 && time < 180000 ? `${sec}s` : '',
  ].join(' ');
}

export function formatHumanTimeDurationFixed(time: number, maxDigits = 4): string {
  if (!Number.isFinite(time) || time < 0) return '';
  if (time === 0) return '0s';

  let sec = Math.floor(time / 1000);
  let min = Math.floor(sec / 60);
  let hour = Math.floor(min / 60);
  const day = Math.floor(hour / 24);

  sec = sec % 60;
  min = min % 60;
  hour = hour % 24;

  let value: number;
  let unit: string;

  if (day > 0) {
    value = day;
    unit = 'd';
  } else if (hour > 0) {
    value = hour;
    unit = 'h';
  } else if (min > 0) {
    value = min;
    unit = 'm';
  } else {
    value = sec;
    unit = 's';
  }

  // Format the numeric part so that it uses at most maxDigits digits.
  let valueStr: string;
  if (value < 10 ** maxDigits) {
    valueStr = value.toString();
  } else {
    valueStr = value.toExponential(0);
  }

  return valueStr + unit;
}

export function formatTime(time: number, withoutSeconds?: boolean): string {
  if (!Number.isFinite(time)) return '';
  if (time === 0) {
    return '00:00:00';
  }
  const date = new Date(time);

  const min = date.getMinutes();
  const hour = date.getHours();
  const sec = date.getSeconds();

  return `${leftpad2(hour)}:${leftpad2(min)}${withoutSeconds ? '' : `:${leftpad2(sec)}`}`;
}

export function formatDate(time: number): string {
  const date = new Date(time);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${leftpad2(month)}-${leftpad2(day)}`;
}

/* 
  formate a date string or a unix timestamp to a human readable string 

  time: a unix timestamp or a date string
  withoutSeconds: if true, the seconds will be omitted
*/
export function formatDateAndTime(
  time: number | string | undefined,
  withoutSeconds?: boolean,
): string {
  if (time == null) return '';

  const timestamp = typeof time === 'string' ? Date.parse(time) : time;
  return `${formatDate(timestamp)} ${formatTime(timestamp, withoutSeconds)}`;
}

export function formatOffsetInMeterBasedOnUnitType(
  offsetM: number | null | undefined,
  unitType: string,
  digits?: number,
): string {
  if (unitType === UnitType.IMPERIAL) return formatMiles(offsetM, digits);
  return formatKM(offsetM, digits);
}

export function formatKM(offsetM: number | null | undefined, digits = 2, maxLength = 7): string {
  if (offsetM == null || !Number.isFinite(offsetM)) return '';
  const offsetKM = offsetM / 1000;
  return `${formatDistanceValue(offsetKM, digits, maxLength)}${HAIRSP}km`;
}

export function formatMeters(offset: number, digits = 2, maxLength = 7): string {
  if (!Number.isFinite(offset)) return '';
  return `${formatDistanceValue(offset, digits, maxLength)}${HAIRSP}m`;
}

export function formatMiles(offset: number | null | undefined, digits = 2, maxLength = 7): string {
  if (offset == null || !Number.isFinite(offset)) return '';
  const offsetMiles = offset * 0.00062137;
  return `${formatDistanceValue(offsetMiles, digits, maxLength)}${HAIRSP}mi`;
}

export function formatFoots(offsetInMeter: number | null | undefined, digits = 2): string {
  if (offsetInMeter == null || !Number.isFinite(offsetInMeter)) return '';
  const offsetFoots = offsetInMeter * 3.2808;
  return `${floorTo(offsetFoots, digits)}${HAIRSP}ft`;
}

export function formatShortDistance(
  distanceInMeter: number,
  unitType: UnitType,
  digits = 2,
): string {
  if (unitType === UnitType.IMPERIAL) return formatFoots(distanceInMeter, digits);
  return formatMeters(distanceInMeter, digits);
}

export function formatSpeed(
  offset: number | null | undefined,
  unitType: string,
  digits = 1,
): string {
  if (offset == null || !Number.isFinite(offset)) return '';
  const speed = offset * 3600;
  if (unitType === UnitType.IMPERIAL) return `${floorTo(speed * 0.62137, digits)}${HAIRSP}mph`;
  return `${floorTo(speed, digits)}${HAIRSP}km/h`;
}

export function formatJSONTime(time?: string | number | null): string | undefined {
  if (time == null) return undefined;
  const d = new Date(time);
  if (!isValidDate(d)) return undefined;

  return d.toISOString();
}

// TODO: Move that to pull-raceresult service.
export function parseTagObjectLegacy(tags: string): Record<string, string> {
  if (tags != null) {
    const tagPairs = tags.split(',').map((item) => item.split(':').map((s) => s.trim()));
    const validPairs = tagPairs.filter((p) => typeof p[1] === 'string' && p[1] !== '');
    return Object.fromEntries(validPairs);
  }
  return {};
}

export function parseNestedObject(
  record: Array<string>,
  mapping: Record<string, number>,
): Record<string, string> {
  // parse prefix based csv collumns, so for example you want to store { times: { start: '2022-09-12T13:49:09.669Z', end: '2022-09-12T13:49:09.669Z' }}
  // you add for every entry a column with the record name as prefix and the key as suffix, splitted by a dot or underscore
  // ...,times_start,times_end,...
  // ...,2022-09-12T13:49:09.669Z,2022-09-12T13:49:09.669Z,...
  const output: Record<string, string> = {};

  for (const [header, columnIndex] of Object.entries(mapping)) {
    const splitter = getSplitter(header);
    // receive the second part of the header without the top-topic, for example times_start -> start
    const headerPure = header.split(splitter)[1];
    if (headerPure == null)
      throw new Error(`CSV Parsing Error: Receive a wrong prefixed subcolumn. Header: ${header}`);

    const value = record[columnIndex];
    if (value == null || value.trim() === '') continue;
    output[headerPure] = value;
  }

  return output;
}

const getSplitter = (header: string): '.' | '_' => {
  if (header.includes('.')) return '.';
  if (header.includes('_')) return '_';
  throw new Error(
    `CSV Parsing Error: Receive a wrong prefixed subcolumn. Use '.' or '_'. Header: ${header}`,
  );
};

// TODO: Legacy -> Remove
export function formatTagObject(tags: Record<string, string>): string {
  if (tags != null) {
    return Object.entries(tags)
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ');
  }
  return '';
}

export function formatNestedObject(
  object: Record<string, string | undefined>,
  possibleKeys: Array<string>,
): Array<string> {
  return possibleKeys.map((k) => object[k] || '');
}

// convert one byte hex to binary
export function hex2bin(hex: string): string {
  return Number.parseInt(hex, 16).toString(2).padStart(8, '0');
}

export function kilometersPerHourInMetersPerSecond(speed: number): number {
  return speed / 3.6;
}

// copied from https://github.com/ThomWright/format-si-prefix
const NoValue = ' - ';
const PREFIXES: { [key: string]: string } = {
  '24': 'Y',
  '21': 'Z',
  '18': 'E',
  '15': 'P',
  '12': 'T',
  '9': 'G',
  '6': 'M',
  '3': 'k',
  '0': '',
  '-3': 'm',
  '-6': 'µ',
  '-9': 'n',
  '-12': 'p',
  '-15': 'f',
  '-18': 'a',
  '-21': 'z',
  '-24': 'y',
};

export const formatSI = (num: number): string => {
  if (num === 0) {
    return '0';
  }
  let sig = Math.abs(num);
  let exponent = 0;
  while (sig >= 1000 && exponent < 24) {
    sig /= 1000;
    exponent += 3;
  }
  while (sig < 1 && exponent > -24) {
    sig *= 1000;
    exponent -= 3;
  }

  const signPrefix = num < 0 ? '-' : '';
  if (sig > 1000) {
    // exponent == 24
    // significand can be arbitrarily long
    return `${signPrefix + sig.toFixed(0)} ${PREFIXES[exponent]}`;
  }
  return `${signPrefix + Number.parseFloat(sig.toPrecision(3))} ${PREFIXES[exponent]}`;
};

export const formatSIWithUnit = (
  aValue: number | null | undefined,
  aUnit = '',
  NoValueOerride = null,
): string => {
  if (
    aValue != null &&
    aValue !== Number.POSITIVE_INFINITY &&
    aValue !== Number.NEGATIVE_INFINITY &&
    !Number.isNaN(aValue)
  ) {
    return `${formatSI(aValue)}${aUnit}`.trimRight();
  }
  return NoValueOerride || NoValue;
};

/**
 * Helper function to format a numeric distance value.
 *
 * @param value - The distance value (already in km or miles) to format.
 * @param digits - The number of digits to use for floor rounding.
 * @param maxLength - The maximum allowed length of the numeric part.
 * @returns A formatted string representing the numeric part.
 */
export function formatDistanceValue(value: number, digits: number, maxLength: number): string {
  let valueStr = floorTo(value, digits).toString();

  if (valueStr.includes('.')) {
    if (valueStr.length > maxLength) {
      const integerPart = valueStr.split('.')[0];
      valueStr = integerPart.length <= maxLength ? integerPart : value.toExponential(2);
    }
  } else if (valueStr.length > maxLength) {
    valueStr = value.toExponential(2);
  }

  return valueStr;
}

export function formatLabel(parts: Array<string | undefined | null>): string {
  const label = parts.filter((item) => item != null && item !== '').join(' ');
  return label || 'N/A';
}
